import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

export default () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logos/dna.png"}) {
                childImageSharp {
                    gatsbyImageData(height: 65, quality: 90, layout: FIXED)
                }
            }
        }
    `)

    return <>
        <div className="pb-3">
            <GatsbyImage
                image={data.logo.childImageSharp.gatsbyImageData}
                alt="Logo DNA"
                className="img-fluid" />
        </div>
        <Addres />
    </>;
}

export const Addres = () => {
    return (
        <address className="small">
            <a href="tel:0367370591">036 737 0591</a><br />
            <a href="mailto:info@dna-makelaars.nl">info@dna-makelaars.nl</a><br />
            <a href="https://www.dna-makelaars.nl" target="_blank" rel="noopener noreferrer">www.dna-makelaars.nl</a><br />
        </address>
    )
}
