import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";


export default () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logos/realisatie.png"}) {
                childImageSharp {
                    gatsbyImageData(height: 65, quality: 90, layout: FIXED)
                }
            }
        }
    `)

  return (
      <div className="d-inline-block">
        <div className="pb-3">
          <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              alt="Logo De Realisatie"
              className="img-fluid" />
        </div>
          <Addres />
      </div>
  );
}

export const Addres = () => {
    return (
        <address className="small">
            Antennestraat 86 kamer D<br />
            1322 AS Almere<br />
            <a href="mailto:info@de-realisatie.nl">info@de-realisatie.nl</a><br />
            <a href="https://www.de-realisatie.nl" target="_blank" rel="noopener noreferrer">www.de-realisatie.nl</a><br />
        </address>
    )
}
